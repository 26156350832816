import React, { useState, useEffect, useRef } from 'react';

const GearCircle = () => {
    const [numTeeth, setNumTeeth] = useState(6);
    const [particles, setParticles] = useState([]);
    const [activeColor, setActiveColor] = useState(null);
    const [targetIndex, setTargetIndex] = useState(null);
    const requestRef = useRef();
    const intervalRef = useRef();
    const N = Math.max(2, Math.floor(numTeeth));
    const viewBoxSize = 1000;
    const center = viewBoxSize / 2;
  
    // More aggressive scaling function for better distribution
    const getScaleFactor = (n) => {
      if (n <= 6) return Math.min(1, 4.5 / Math.sqrt(n));  // Reduced from 5
      const baseScale = 3.5 / Math.sqrt(n);  // Reduced from 4
      const reductionFactor = Math.pow(0.89, n - 6);  // More aggressive reduction (from 0.92)
      return Math.min(1, baseScale * reductionFactor);
    };
  
    // Adjust base radius based on number of teeth
    const getBaseRadius = (n) => {
      if (n == 6) {
          return viewBoxSize * (0.45 - (n + 3) * 0.007);  // Start smaller and reduce faster
      }
      if (n == 7) {
          return viewBoxSize * (0.45 - (n + 3) * 0.007);  // Start smaller and reduce faster
      }
      if (n == 8) {
          return viewBoxSize * (0.45 - (n - 4 ) * 0.01);  // Start smaller and reduce faster
      }
      if (n == 9) {
          return viewBoxSize * (0.39 - (n - 12) * 0.006);  // Start smaller and reduce faster
      }
      if (n >= 10 && n <= 16) {
        return viewBoxSize * (0.38 - (n - 15) * 0.008);  // Start smaller and reduce faster
      }
      return viewBoxSize * 0.33;  // Smaller default radius
    };
  
    const baseRadius = getBaseRadius(N);
    const scaleFactor = getScaleFactor(N);
    const toothHeight = baseRadius * 0.2 * scaleFactor;
    const rotationOffset = Math.PI / 2;
    const colors = ['#de3500', '#007ed3', '#949494', '#03ae13'].map(color => adjustColorBrightness(color, 1.2)); // Make colors more neon
    const particleColors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD'];
  
    // Helper function to make colors more neon
    function adjustColorBrightness(hex, factor) {
      // Convert hex to RGB
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      
      // Adjust brightness
      const newR = Math.min(255, Math.floor(r * factor));
      const newG = Math.min(255, Math.floor(g * factor));
      const newB = Math.min(255, Math.floor(b * factor));
      
      // Convert back to hex
      return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
    }
  
    const getPoint = (angle, radius) => ({
      x: center + radius * Math.cos(angle),
      y: center + radius * Math.sin(angle)
    });
  
    const getInnerPoint = (angle) => getPoint(angle, baseRadius - toothHeight * 2);
    const getCollisionPoint = (angle) => getPoint(angle, baseRadius - toothHeight * 2);
  
    const createParticle = (fromAngle, color, targetedIndex) => {
        let toIdx;
        if (targetedIndex !== null) {
          toIdx = targetedIndex;
        } else {
            if (targetIndex !== null) {
                toIdx = targetIndex;
              } else {
          do {
            toIdx = Math.floor(Math.random() * N);
          } while (Math.abs(toIdx - 0) < 0.1);
              }
        }
    
        const toAngle = rotationOffset + (2 * Math.PI * toIdx) / N;
        const fromPoint = getInnerPoint(fromAngle);
        const toPoint = getCollisionPoint(toAngle);
    
        return {
          id: Math.random(),
          fromX: fromPoint.x,
          fromY: fromPoint.y - scaleFactor * 100,
          toX: toPoint.x,
          toY: toPoint.y,
          x: fromPoint.x,
          y: fromPoint.y - scaleFactor * 100,
          progress: 0,
          color: color,
          speed: 0.008 + Math.random() * 0.006,
          exploding: false,
          explosionParticles: []
        };
      };
    
      // Add numTeeth to dependency array and cleanup animations
      useEffect(() => {
        // Clear existing particles when numTeeth changes
        setParticles([]);
        
        // Clear any existing animation frame
        if (requestRef.current) {
          cancelAnimationFrame(requestRef.current);
        }
        
        // Clear any existing intervals
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }

        const animate = () => {
          setParticles(prev => {
            const updated = prev
              .map(p => {
                if (!p.exploding && p.progress >= 0.99) {
                  // Explosion effect
                  const explosionParticles = Array.from({ length: 12 }).map(() => ({
                    x: p.x,
                    y: p.y,
                    vx: (Math.random() - 0.5) * 30,
                    vy: (Math.random() - 0.5) * 30,
                    radius: 3 * scaleFactor,
                    color: p.color,
                    life: 0,
                    maxLife: 30 + Math.floor(Math.random() * 30)
                  })).filter((particle) => {
                    return (center - (particle.x + particle.vx) > (center - particle.x) && particle.x > center) ||
                    (particle.x + particle.vx - center > particle.x - center && particle.x < center) ||
                    (center - (particle.y + particle.vy) > (center - particle.y) && particle.y > center) ||
                    (particle.y + particle.vy - center > particle.y - center && particle.y < center);
                  });
                  return { ...p, exploding: true, explosionParticles };
                }
    
                if (p.exploding) {
                  return {
                    ...p,
                    explosionParticles: p.explosionParticles.map(ep => ({
                      ...ep,
                      x: ep.x + ep.vx,
                      y: ep.y + ep.vy,
                      vx: ep.vx * 0.95,
                      vy: ep.vy * 0.95,
                      life: ep.life + 1
                    })).filter(ep => ep.life < ep.maxLife)
                  };
                }
    
                return {
                  ...p,
                  progress: p.progress + p.speed,
                  x: p.fromX + (p.toX - p.fromX) * p.progress,
                  y: p.fromY + (p.toY - p.fromY) * p.progress
                };
              })
              .filter(p => p.progress < 1 || (p.exploding && p.explosionParticles.length > 0));
    
            if (Math.random() < 0.004) {
                const fromIdx = Math.floor(Math.random() * (N) + 1);
                const fromAngle = rotationOffset + (2 * Math.PI * fromIdx) / N;
                const numParticlesToGen = Math.random() * 20;
                const particleColor = colors[Math.floor(Math.random() * colors.length)];
                const victim = Math.floor(Math.random() * N);
                if (victim != fromIdx) {
                    for (let particleIndex = 0; particleIndex < numParticlesToGen; particleIndex++) {
                        updated.push(createParticle(fromAngle, particleColor, victim));
                    }
                }
              }
    
            return updated;
          });
          requestRef.current = requestAnimationFrame(animate);
        };
        
        requestRef.current = requestAnimationFrame(animate);
        
        return () => {
          if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
          }
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        };
      }, [numTeeth, targetIndex]); // Added numTeeth to dependencies


  const handleHexagonClick = (index) => {
    if (index === 0) return;
    setTargetIndex(targetIndex === index ? null : index);
  };

  const handleNumTeethChange = (e) => {
    const newNumTeeth = Math.max(1, Math.min(25, parseInt(e.target.value, 10)));
    setNumTeeth(newNumTeeth);
    // Reset target index when changing number of teeth
    setTargetIndex(null);
    // Reset active color
    setActiveColor(null);
  };

  const startParticleGeneration = (color) => {
    setParticles(prev => [
      ...prev,
      ...Array(3).fill().map(() => createParticle(Math.PI * 0.5, color, targetIndex))
    ]);

    setActiveColor(color);
    intervalRef.current = setInterval(() => {
      setParticles(prev => [
        ...prev,
        ...Array(2).fill().map(() => createParticle(Math.PI * 0.5, color, targetIndex))
      ]);
    }, 100);
  };

  const stopParticleGeneration = () => {
    clearInterval(intervalRef.current);
    setActiveColor(null);
  };

  const handleLineInteraction = (color, e) => {
    startParticleGeneration(color);
  };

  const generateWrappingLines = (centerAngle, index) => {
    const isBottom = index === 0;
    const hexagonScale = isBottom ? 1.0 : 1.0; // Increased from 1.3 to 1.4
    const hexRadius = toothHeight * 0.7 * hexagonScale;
    const basePoint = getPoint(centerAngle, baseRadius - (toothHeight * (isBottom ? 1.1 : 0.8))); // More offset for bottom hexagon
    
    // Adjust line gap based on number of teeth
    const getLineGap = () => {
      if (isBottom) {
        // Increased base gap for bottom hexagon
        const baseGap = 22 * scaleFactor;
        // Add extra gap for specific tooth counts
        if (N >= 7 && N <= 16) {
          return baseGap * (1 + (N - 6) * 0.02);
        }
        return baseGap;
      }
      return 7 * scaleFactor * Math.max(0.5, (1 - (N - 7) * 0.03));
    };
    
    const lineGap = getLineGap();
    
    return colors.map((color, i) => {
      const offset = hexRadius + (i * lineGap) + (isBottom ? 26 : 24); // Increased base offset for bottom hexagon
      const angles = isBottom 
        ? [0, Math.PI/3, 2*Math.PI/3, Math.PI, 4*Math.PI/3, 5*Math.PI/3]  // Point up
        : [Math.PI/6, Math.PI/2, 5*Math.PI/6, 7*Math.PI/6, 3*Math.PI/2, 11*Math.PI/6]; // Original
      
      return angles.map((angle, j) => (
        <line
          key={`line-${i}-${j}`}
          x1={basePoint.x + offset * Math.cos(centerAngle + angles[j])}
          y1={basePoint.y + offset * Math.sin(centerAngle + angles[j])}
          x2={basePoint.x + offset * Math.cos(centerAngle + angles[(j+1)%angles.length])}
          y2={basePoint.y + offset * Math.sin(centerAngle + angles[(j+1)%angles.length])}
          stroke={isBottom ? color : 'white'}
          strokeWidth={isBottom ? 16 * scaleFactor : 4 * scaleFactor} // Increased from 14 to 16
          strokeLinecap="round" // Added rounded line caps
          strokeLinejoin="round" // Added rounded line joins
          className={isBottom ? 'cursor-pointer touch-action-none' : ''}
          onMouseDown={isBottom ? (e) => handleLineInteraction(color, e) : undefined}
          onMouseUp={stopParticleGeneration}
          onMouseLeave={stopParticleGeneration}
          onTouchStart={isBottom ? (e) => handleLineInteraction(color, e) : undefined}
          onTouchEnd={stopParticleGeneration}
          style={isBottom ? { 
            cursor: 'pointer', 
            touchAction: 'none',
            zIndex: 10,
            filter: color === activeColor ? 'brightness(1.5) drop-shadow(0 0 3px ' + color + ')' : 'drop-shadow(0 0 2px ' + color + ')'
          } : { zIndex: 1 }}
        />
      ));
    });
  };

  return (
    <div className="w-screen h-screen bg-gray-900 flex items-center justify-center touch-none select-none">
    <div className="absolute top-4 right-4 flex items-center gap-2">
        <label htmlFor="num-teeth" className="text-white">Players:</label>
        <input
          id="num-teeth"
          type="number"
          min="1"
          max="25"
          value={numTeeth}
          onChange={handleNumTeethChange}
          className="bg-gray-800 text-white px-2 py-1 rounded-md"
        />
      </div>
<svg
  viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
  className="w-full h-full p-4"
  preserveAspectRatio="xMidYMid meet"
>        <defs>
          <filter id="glow">
            <feGaussianBlur stdDeviation="3" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <filter id="targetGlow">
            <feGaussianBlur stdDeviation="8" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        
        {Array.from({ length: N }).map((_, i) => {
          const angle = rotationOffset + (2 * Math.PI * i) / N;
          const isTarget = i === targetIndex;
          const point = getPoint(angle, baseRadius - (toothHeight * 0.8));
          
          return (
            <React.Fragment key={`section-${i}`}>
              {generateWrappingLines(angle, i)}
              <g 
                onClick={() => handleHexagonClick(i)}
                style={{ cursor: i === 0 ? 'default' : 'pointer', zIndex: ( i === 0 ? 10 : 0 ) }}
              >
                {/* Invisible larger click area */}
                <circle
                  cx={point.x}
                  cy={point.y}
                  r={(i === 0 ? 40 : 90) * scaleFactor}
                  fill="transparent"
                  style={{ cursor: i === 0 ? 'default' : 'pointer' }}
                />
                
                {isTarget && (
                  <circle
                    cx={point.x}
                    cy={point.y}
                    r={30 * scaleFactor}
                    fill="#8A2BE2"
                    opacity="0.5"
                    filter="url(#targetGlow)"
                  />
                )}
                <circle
                  cx={point.x}
                  cy={(i === 0 ? point.y - (23 * scaleFactor) : point.y)}
                  r={(i === 0 ? 30 : 24) * scaleFactor}
                  fill={isTarget ? "#44ff9940" : "#44ff9930"}
                  filter="url(#glow)"
                >
                  <animate
                    attributeName="r"
                    values={`${(i === 0 ? 26 : 20) * scaleFactor};${(i === 0 ? 28 : 22) * scaleFactor};${(i === 0 ? 26 : 20) * scaleFactor}`}
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle
                  cx={point.x}
                  cy={(i === 0 ? point.y - (23 * scaleFactor) : point.y)}
                  r={(i === 0 ? 15 : 12) * scaleFactor}
                  fill={isTarget ? "#44ff9980" : "#44ff9960"}
                />
              </g>
            </React.Fragment>
          );
        })}
        
        {particles.map(p => (
          <React.Fragment key={p.id}>
            {p.exploding ? (
              p.explosionParticles.map((ep, i) => (
                <circle
                  key={`${p.id}-explosion-${i}`}
                  cx={ep.x}
                  cy={ep.y}
                  r={ep.radius}
                  fill={ep.color}
                  opacity={1 - ep.life / ep.maxLife}
                />
              ))
            ) : (
              <circle
                key={p.id}
                cx={p.x}
                cy={p.y}
                r={12 * scaleFactor}
                fill={p.color}
                opacity={1 - p.progress * 0.3}
                filter="url(#glow)"
              >
                <animate
                  attributeName="r"
                  values={`${12 * scaleFactor};${16 * scaleFactor};${12 * scaleFactor}`}
                  dur="0.8s"
                  repeatCount="indefinite"
                />
              </circle>
            )}
          </React.Fragment>
        ))}
      </svg>
    </div>
  );
};

export default GearCircle;